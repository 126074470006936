<template>
  <v-row class="pa-2">
    <Overlay :loading="loading" />
    <v-col cols="12" class="text-h5 black--text pb-0">
      <v-row>
        <v-col cols="6" sm="8" md="10">Atendimentos</v-col>
        <v-col cols="6" sm="4" md="2">
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="subtitle-2 grey--text pt-0">
      Lista de atendimentos realizados
    </v-col>
    <v-col cols="12" class="pb-0">
      <v-col cols="12" md="12" lg="2" style="white-text: no-wrap"
        >Filtrar por:</v-col
      >
      <v-row class="">
        <!-- Paciente -->
        <v-col cols="12" sm="4" md="3" lg="4">
          <v-text-field
            v-model="searchPatient"
            dense
            outlined
            label="Buscar por nome do paciente"
          ></v-text-field>
        </v-col>
        <!-- Data -->
        <v-col cols="12" sm="2" md="2" lg="2">
          <v-menu
            ref="searchDateMenuAttendance"
            v-model="menuDate"
            :close-on-content-click="false"
            :return-value.sync="searchDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="searchDate"
                label="Data"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="searchDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuDate = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.searchDateMenuAttendance.save(searchDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <!-- Status -->
        <v-col cols="12" sm="2" md="2" lg="3">
          <v-select
            v-model="searchStatus"
            :items="status"
            label="Filtrar Status"
            menu-props="auto"
            outlined
            dense
            single-line
          ></v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-card outlined class="elevation-0 rounded-0">
        <!-- possiveis filtros searchResponsible -->
        <v-data-table
          :headers="headers"
          :items="attendancies"
          :search="searchPatient || searchDate || searchStatus"
          :loading="!attendancies"
          item-key="id"
          :custom-filter="filterOnlyText"
          @current-items="getFiltered"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div
              @click="
                $router.push({
                  name: 'PatientShowAttendance',
                  params: {
                    id: item.patient_id,
                    attendance_id: item.id,
                    data: item,
                  },
                })
              "
            >
              {{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.patient`]="{ item }">
            <div
              @click="
                $router.push({
                  name: 'PatientShowAttendance',
                  params: {
                    id: item.patient_id,
                    attendance_id: item.id,
                    data: item,
                  },
                })
              "
            >
              {{ item.patient }}
            </div>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <div
              @click="
                $router.push({
                  name: 'PatientShowAttendance',
                  params: {
                    id: item.patient_id,
                    attendance_id: item.id,
                    data: item,
                  },
                })
              "
            >
              {{ item.date }}
            </div>
          </template>
          <template v-slot:[`item.responsible`]="{ item }">
            <div
              class=""
              @click="
                $router.push({
                  name: 'PatientShowAttendance',
                  params: {
                    id: item.patient_id,
                    attendance_id: item.id,
                    data: item,
                  },
                })
              "
            >
              {{ item.responsible }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div
              class="font-weight-bold"
              :style="'color: ' + item.status.color"
              @click="
                $router.push({
                  name: 'PatientShowAttendance',
                  params: {
                    id: item.patient_id,
                    attendance_id: item.id,
                    data: item,
                  },
                })
              "
            >
              {{ item.status.title }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              @click="
                $router.push({
                  name: 'PatientShowAttendance',
                  params: {
                    id: item.patient_id,
                    attendance_id: item.id,
                    data: item,
                  },
                })
              "
              width="30"
              elevation="0"
              color="transparent"
              ><v-icon icon>mdi-eye</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
// import Excel from "../../components/Excel/Excel.vue";
import Overlay from "../../components/Overlay/Overlay.vue";

export default {
  components: {
    Overlay,
    // Excel,
  },
  data: () => ({
    loading: false,
    menuDate: false,
    searchResponsible: null,
    searchPatient: null,
    searchDate: null,
    searchStatus: null,
    status: [
      {
        text: "Filtrar por Status",
        value: null,
      },
      { text: "Agendado",                             value: "scheduled" },
      { text: "Em análise",                           value: "under_review" },
      { text: "Concluído",                            value: "concluded" },
      { text: "Encerrado",                            value: "closed" },
      { text: "Encaminhar equipe",                    value: "forward" },
      { text: "Entrar em contato familiar",           value: "get_family" },
    ],
    attendancies: [],
    excelData: [],
    json_fields_attendance: {
      Id: "id",
      Data: {
        field: "date",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Responsável: {
        field: "responsible",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Descrição: {
        field: "subject_matter",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Paciente: {
        field: "patient",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
    },
  }),
  mounted() {
    this.showAttendacies();
  },
  methods: {
    async showAttendacies() {
      this.loading = true;
      try {
        const response = await this.$axios("attendances", {
          params: {
            doctor_id: this.currentUser.id,
          },
        });

        if (response.data) {
          response.data.map((el) => {
            this.attendancies = [
              {
                date: this.formatDateTime(el.updated_at),
                id: el.id,
                status: this.scheduleStatusFormatting(el.status),
                responsible: el.responsible.name,
                urgency: el.alert.risk == "high" ? "Alta" : "Baixa",
                subject_matter: el.subject_matter,
                sensor: el.sensor,
                service: el.service,
                annotation: el.annotation,
                patient: el.patient.name,
                patient_id: el.patient.id,
                alert: el.alert,
                alert_id: el.alert_id,
                created_at: this.formatDateTime(el.created_at),
              },
              ...this.attendancies,
            ];
          });
        }
        this.loading = false;
      } catch (error) {
        const msg = error.response.error;

        console.log("Show attendancies error: ", error);
        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar os atendimentos." + msg,
        });

        this.loading = false;
      }
    },
    getFiltered(e) {
      this.excelData = e;
    },
    formatDateTime(dataTime) {
      const day = dataTime.slice(8, 10);
      const month = dataTime.slice(5, 7);
      const year = dataTime.slice(0, 4);
      const time = dataTime.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    scheduleStatusFormatting(status) {
      switch (status) {
        case "scheduled":
          return {
            title: "Agendado",
            color: "#096FA9",
            value: status,
          };
        case "under_review":
          return {
            title: "Em análise",
            color: "#ED7F30",
            value: status,
          };
        case "concluded":
          return {
            title: "Concluído",
            color: "#09A90F",
            value: status,
          };
        case "closed":
          return {
            title: "Encerrado",
            color: "#CF4444",
            value: status,
          };
        case "forward":
          return {
            title: "Encaminhar equipe",
            color: "#CF4444",
            value: status,
          };
        case "get_family":
          return {
            title: "Entrar em contato familiar",
            color: "#CF4444",
            value: status,
          };
        default:
          return {
            title: "Agendado",
            color: "#096FA9",
            value: status,
          };
      }
    },
    filterOnlyText(value, search) {
      const isDate = search.split("-");

      if (isDate.length > 1) search = `${isDate[2]}/${isDate[1]}/${isDate[0]}`;

      return (
        (value != null &&
          search != null &&
          typeof value === "string" &&
          value.toString().toLowerCase().indexOf(search.toLowerCase()) !==
            -1) ||
        (typeof value === "object" &&
          value.value.toString().toLowerCase().indexOf(search.toLowerCase()) !==
            -1)
      );
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
    headers() {
      return [
        {
          text: "Número",
          value: "id",
          width: 100,
          align: "center",
        },
        {
          text: "Nome do paciente",
          value: "patient",
          width: 100,
          align: "center",
        },  
        {
          text: "Status",
          value: "status",
          width: 250,
          align: "center",
          sortable: false,
        },
        {
          text: "Profissional",
          value: "responsible",
          width: 150
        },
        { text: "Data", value: "date", width: 100, align: "center" },
        { text: "Ação", value: "action", width: 80, align: "center" },
      ];
    },
  },
};
</script>

<style></style>
