var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-2"},[_c('Overlay',{attrs:{"loading":_vm.loading}}),_c('v-col',{staticClass:"text-h5 black--text pb-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"8","md":"10"}},[_vm._v("Atendimentos")]),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"2"}})],1)],1),_c('v-col',{staticClass:"subtitle-2 grey--text pt-0",attrs:{"cols":"12"}},[_vm._v(" Lista de atendimentos realizados ")]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-col',{staticStyle:{"white-text":"no-wrap"},attrs:{"cols":"12","md":"12","lg":"2"}},[_vm._v("Filtrar por:")]),_c('v-row',{},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","lg":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Buscar por nome do paciente"},model:{value:(_vm.searchPatient),callback:function ($$v) {_vm.searchPatient=$$v},expression:"searchPatient"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2","lg":"2"}},[_c('v-menu',{ref:"searchDateMenuAttendance",attrs:{"close-on-content-click":false,"return-value":_vm.searchDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.searchDate=$event},"update:return-value":function($event){_vm.searchDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Data","readonly":"","clearable":""},model:{value:(_vm.searchDate),callback:function ($$v) {_vm.searchDate=$$v},expression:"searchDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.searchDate),callback:function ($$v) {_vm.searchDate=$$v},expression:"searchDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuDate = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.searchDateMenuAttendance.save(_vm.searchDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.status,"label":"Filtrar Status","menu-props":"auto","outlined":"","dense":"","single-line":""},model:{value:(_vm.searchStatus),callback:function ($$v) {_vm.searchStatus=$$v},expression:"searchStatus"}})],1)],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-0 rounded-0",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.attendancies,"search":_vm.searchPatient || _vm.searchDate || _vm.searchStatus,"loading":!_vm.attendancies,"item-key":"id","custom-filter":_vm.filterOnlyText},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.$router.push({
                name: 'PatientShowAttendance',
                params: {
                  id: item.patient_id,
                  attendance_id: item.id,
                  data: item,
                },
              })}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.patient",fn:function(ref){
              var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.$router.push({
                name: 'PatientShowAttendance',
                params: {
                  id: item.patient_id,
                  attendance_id: item.id,
                  data: item,
                },
              })}}},[_vm._v(" "+_vm._s(item.patient)+" ")])]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.$router.push({
                name: 'PatientShowAttendance',
                params: {
                  id: item.patient_id,
                  attendance_id: item.id,
                  data: item,
                },
              })}}},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:"item.responsible",fn:function(ref){
              var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.$router.push({
                name: 'PatientShowAttendance',
                params: {
                  id: item.patient_id,
                  attendance_id: item.id,
                  data: item,
                },
              })}}},[_vm._v(" "+_vm._s(item.responsible)+" ")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:('color: ' + item.status.color),on:{"click":function($event){return _vm.$router.push({
                name: 'PatientShowAttendance',
                params: {
                  id: item.patient_id,
                  attendance_id: item.id,
                  data: item,
                },
              })}}},[_vm._v(" "+_vm._s(item.status.title)+" ")])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"width":"30","elevation":"0","color":"transparent"},on:{"click":function($event){return _vm.$router.push({
                name: 'PatientShowAttendance',
                params: {
                  id: item.patient_id,
                  attendance_id: item.id,
                  data: item,
                },
              })}}},[_c('v-icon',{attrs:{"icon":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }